import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { store } from './store';
import AppContent from './AppContent';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <AppContent />
        </I18nextProvider>
      </Router>
    </Provider>
  );
};

export default App;