import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo512.png';
import background from '../assets/images/FAQ.png';

interface FAQ {
  questionKey: string;
  answerKey: string;
}

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  
  const faqs: FAQ[] = [
    {
      questionKey: 'faq.preventiveHealth.question',
      answerKey: 'faq.preventiveHealth.answer',
    },
    {
      questionKey: 'faq.aiUtilization.question',
      answerKey: 'faq.aiUtilization.answer',
    },
    {
      questionKey: 'faq.languages.question',
      answerKey: 'faq.languages.answer',
    },
    {
      questionKey: 'faq.topics.question',
      answerKey: 'faq.topics.answer',
    },
    {
      questionKey: 'faq.contribute.question',
      answerKey: 'faq.contribute.answer',
    },
    {
      questionKey: 'faq.subscription.question',
      answerKey: 'faq.subscription.answer',
    }
  ];

  return (
    <div className="mx-0 md:mx-4 mt-64 relative">
      {/* Logo Container - Positioned absolutely relative to parent */}
      <div className="absolute left-1/2 -top-24 transform -translate-x-1/2 z-10">
        <img 
          src={logo} 
          alt={t('common.logoAlt')} 
          className="w-48 h-48 object-contain"
        />
      </div>

      {/* Main Content Container */}
      <div className="relative ">
        {/* Background Image */}
        <div 
          className="absolute inset-0 w-full h-full bg-cover bg-center "
          style={{ 
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        />
        
        {/* Content Container */}
        <div className="relative py-16">
          <div className="max-w-7xl mx-auto px-4">
            {/* Title */}
            <div className="flex flex-col items-center mt-16 mb-12">
              <h2 className="text-3xl font-bold text-center text-black">
                {t('faq.title')}
              </h2>
            </div>

            {/* FAQ Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {faqs.map((faq, index) => (
                <div 
                  key={index}
                  className="p-6 hover:bg-white/10 transition-colors duration-300 rounded-lg"
                >
                  <h3 className="text-lg font-semibold text-[#111D4E] mb-2">
                    {t(faq.questionKey)}
                  </h3>
                  <p className="text-gray-600">
                    {t(faq.answerKey)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;