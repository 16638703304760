import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import clouds from '../assets/images/clouds.png';
import FAQ from './FAQ';
import VideoSection from './VideoSection';
import RecommendationsComponent from './RecommendationsComponent';
import logo from '../assets/images/logo_with_text.png';
import butterfly from '../assets/videos/butterfly.mp4';
import header from '../assets/videos/header.mp4';
import PlaylistCarousel from './PlaylistCarousel';
import HearticleDisplay from './HearticleDisplay';
import { FutureHearticlesComponent } from './FutureHearticles';
import { PastHearticlesComponent } from './PastHearticles';
import UnpublishedPlaylistCarousel from './UnpublishedPlaylistCarousel';
import { useSearchParams } from 'react-router-dom';
import HearticleExplorer from './HearticleExplorer';

const HeartPort: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const hasReviewPermission = user?.roles.includes('PERMISSION_REVIEW_HEARTICLES');
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const [searchParams] = useSearchParams();
  const isExploreMode = searchParams.get('mode') === 'explore';

  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };

  return (
    <div className="overflow-x-hidden">
      <div style={backgroundStyle} className="min-h-screen mx-0 lg:mx-4 mb-24">
        <div style={cloudImageStyle}></div>
        <div style={overlayStyle}></div>
        
        <div className="relative z-10">
          <header className="flex justify-between p-4 items-center lg:mb-4">
            <div className="flex items-center">
              <img src={logo} alt="HeartPort logo" className="mr-2 w-auto h-14 md:w-auto md:h-auto" />
            </div>
          </header>

          <div className="p-4 lg:mt-10 mt-0">
            <h1 className="text-3xl lg:text-8xl font-bold mb-4 text-[#49ABDB]">
              DEMOCRATISING SCIENCE
            </h1>
            
            <div className="absolute right-0 md:right-10 md:top-48 transform lg:w-[150px] lg:h-[150px] w-[100px] h-[100px] -translate-y-[50%] hidden md:block">
              <video 
                src={header} 
                autoPlay 
                loop 
                muted 
                playsInline 
                className="w-full h-full rounded-full"
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>
            <p className="pb-6 text-sm lg:text-xl text-[#5B5B5B] font-bold italic lg:p-2">
              {t('heartport.subtitle')}
            </p>
            {!isAuthenticated && (
              <div className="lg:mb-12 mb-4 rounded-lg lg:p-2">
                <p className="text-[#49ABDB] font-medium mb-2">
                  {t('auth.personalizationBenefits')}
                </p>
                <a 
                  href="/signup" 
                  className="inline-block bg-[#49ABDB] text-white px-6 py-2 rounded-full font-medium hover:bg-[#3891c0] transition-colors"
                >
                  {t('auth.getStarted')}
                </a>
              </div>
            )}
            {isExploreMode ? (
              <HearticleExplorer />
            ) : (
              <HearticleDisplay />
            )}

            <div className="text-center mt-8 lg:mt-16 mb-4 lg:mb-8">
              <h2 className="text-xl lg:text-3xl font-bold text-[#49ABDB]">
                {t('heartport.fightDisease')}
              </h2>
              <h3 className="text-lg lg:text-2xl font-bold text-[#49ABDB] mt-2">
                {t('heartport.tailored')}
              </h3>
              <div className="flex justify-center mt-4 lg:mt-8">
                <video 
                  src={butterfly} 
                  autoPlay 
                  loop 
                  muted 
                  playsInline 
                  className="w-full max-w-[100px] lg:max-w-[150px] mx-auto rounded-full"
                  style={{
                    aspectRatio: '1 / 1',
                    objectFit: 'cover',
                    border: 'none',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Conditionally render Past and Future Hearticles components */}
      {hasReviewPermission && (
        <div className="space-y-8">
          <PastHearticlesComponent />
          <FutureHearticlesComponent />
          <UnpublishedPlaylistCarousel />

        </div>
      )}
      
      <PlaylistCarousel />

      <RecommendationsComponent
        personalizedCount={0}
        generalCount={4}
        headline={t("recommendations.baseHeadline")}
        subheadline={t("recommendations.baseSubheadline")}
        showReadCount={true}
      />

      {isAuthenticated && (
        <RecommendationsComponent
          personalizedCount={4}
          generalCount={0}
          headline={t("recommendations.persHeadline")}
          subheadline={t("recommendations.persSubheadline")}
        />
      )}
        
      <VideoSection />

      <FAQ />
    </div>
  );
};

export default HeartPort;