import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../axios';
import { Plus, Edit2, Trash2 } from 'lucide-react';
import { RootState } from '../store';
import { PaginatedResponse, Hearticle } from '../types';

interface Playlist {
  id: number;
  name: string;
  description: string;
  creationDate: string;
  lastModifiedDate: string;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
  hearticles: Hearticle[];
  creator: {
    id: number;
    username: string;
  };
  editorIds: number[];
}

const PlaylistManagement: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [hearticles, setHearticles] = useState<Hearticle[]>([]);
  const [selectedHearticles, setSelectedHearticles] = useState<Set<number>>(new Set());
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newPlaylist, setNewPlaylist] = useState<{
    name: string;
    description: string;
    status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
}>({
    name: '',
    description: '',
    status: 'DRAFT'
});

  const user = useSelector((state: RootState) => state.auth.user);
  const isAdmin = user?.roles?.includes('PERMISSION_ADMIN');

  useEffect(() => {
    fetchPlaylists();
    fetchHearticles(0);
  }, []);

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get<Playlist[]>('/api/playlist');
      setPlaylists(response.data);
    } catch (error) {
      setError('Failed to fetch playlists');
      console.error(error);
    }
  };

  const fetchHearticles = async (page: number) => {
    try {
      const response = await axios.get<PaginatedResponse<Hearticle>>('/api/hearticle', {
        params: { page, size: 10 }
      });
      setHearticles(response.data.content);
      setTotalPages(response.data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      setError('Failed to fetch hearticles');
      console.error(error);
    }
  };

  const handleCreatePlaylist = async () => {
    try {
      const playlistData = {
        ...newPlaylist,
        hearticleIds: Array.from(selectedHearticles)
      };
      await axios.post('/api/playlist', playlistData);
      setShowCreateModal(false);
      setSelectedHearticles(new Set());
      setNewPlaylist({ name: '', description: '', status: 'DRAFT' });
      fetchPlaylists();
    } catch (error) {
      setError('Failed to create playlist');
      console.error(error);
    }
  };

  const handleDeletePlaylist = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this playlist?')) return;
    
    try {
      await axios.delete(`/api/playlist/${id}`);
      fetchPlaylists();
    } catch (error) {
      setError('Failed to delete playlist');
      console.error(error);
    }
  };

  const toggleHearticleSelection = (hearticleId: number) => {
    const newSelection = new Set(selectedHearticles);
    if (newSelection.has(hearticleId)) {
      newSelection.delete(hearticleId);
    } else {
      newSelection.add(hearticleId);
    }
    setSelectedHearticles(newSelection);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-[#111D4E]">Playlists</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="px-4 py-2 bg-[#49ABDB] text-white rounded-lg flex items-center gap-2 hover:bg-opacity-90"
        >
          <Plus size={20} />
          Create Playlist
        </button>
      </div>

      {/* Playlists List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {playlists.map((playlist) => (
          <div key={playlist.id} className="bg-white rounded-lg p-4 shadow">
            <div className="flex justify-between items-start mb-2">
              <h3 className="font-bold text-lg">{playlist.name}</h3>
              <div className="flex gap-2">
                <button className="p-1 hover:bg-gray-100 rounded">
                  <Edit2 size={16} />
                </button>
                <button 
                  className="p-1 hover:bg-gray-100 rounded"
                  onClick={() => handleDeletePlaylist(playlist.id)}
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-600 mb-2">{playlist.description}</p>
            <div className="flex justify-between items-center text-sm">
              <span className={`px-2 py-1 rounded-full ${
                playlist.status === 'ACTIVE' ? 'bg-green-100 text-green-800' :
                playlist.status === 'DRAFT' ? 'bg-yellow-100 text-yellow-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {playlist.status}
              </span>
              <span className="text-gray-500">
                {playlist.hearticles.length} hearticles
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Create Playlist Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h3 className="text-xl font-bold mb-4">Create New Playlist</h3>
            
            <div className="space-y-4 mb-6">
              <div>
                <label className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg"
                  value={newPlaylist.name}
                  onChange={(e) => setNewPlaylist({...newPlaylist, name: e.target.value})}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <textarea
                  className="w-full px-3 py-2 border rounded-lg"
                  value={newPlaylist.description}
                  onChange={(e) => setNewPlaylist({...newPlaylist, description: e.target.value})}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium mb-1">Status</label>
                <select
                  className="w-full px-3 py-2 border rounded-lg"
                  value={newPlaylist.status}
                  onChange={(e) => setNewPlaylist({...newPlaylist, status: e.target.value as 'ACTIVE' | 'DRAFT' | 'ARCHIVED'})}
                >
                  <option value="DRAFT">Draft</option>
                  <option value="ACTIVE">Active</option>
                  <option value="ARCHIVED">Archived</option>
                </select>
              </div>
            </div>

            <h4 className="font-medium mb-2">Select Hearticles</h4>
            <div className="border rounded-lg mb-4">
              {hearticles.map((hearticle) => (
                <div 
                  key={hearticle.id}
                  className="flex items-center p-3 border-b last:border-b-0 hover:bg-gray-50"
                >
                  <input
                    type="checkbox"
                    checked={selectedHearticles.has(hearticle.id)}
                    onChange={() => toggleHearticleSelection(hearticle.id)}
                    className="mr-3"
                  />
                  <div>
                    <h5 className="font-medium">
                      {hearticle.translations[0]?.headline || `Hearticle ${hearticle.id}`}
                    </h5>
                    <p className="text-sm text-gray-600">
                      {hearticle.topic.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-between items-center">
              <div className="space-x-2">
                <button
                  onClick={() => currentPage > 0 && fetchHearticles(currentPage - 1)}
                  disabled={currentPage === 0}
                  className="px-3 py-1 border rounded disabled:opacity-50"
                >
                  Previous
                </button>
                <button
                  onClick={() => currentPage < totalPages - 1 && fetchHearticles(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                  className="px-3 py-1 border rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
              <div className="space-x-2">
                <button
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreatePlaylist}
                  className="px-4 py-2 bg-[#49ABDB] text-white rounded-lg hover:bg-opacity-90"
                >
                  Create Playlist
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistManagement;