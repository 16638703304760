import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { AlertCircle, Check, X, Edit2, Save } from 'lucide-react';
import api from '../axios';
import { Button } from '../components/Button';
import { Review, ReviewStatus, ReviewChange, HearticleTranslation, Hearticle } from '../types';
import { useTranslation } from 'react-i18next';

interface DiffViewerProps {
  oldText: string;
  newText: string;
  label: string;
  onEdit?: (newValue: string) => void;
  isEditing: boolean;
  onToggleEdit: () => void;
}

const DiffViewer: React.FC<DiffViewerProps> = ({ 
  oldText = '', 
  newText = '', 
  label,
  onEdit,
  isEditing,
  onToggleEdit
}) => {
  const [editValue, setEditValue] = useState(newText);

  useEffect(() => {
    setEditValue(newText);
  }, [newText]);

  const handleSave = () => {
    onEdit?.(editValue);
    onToggleEdit();
  };

  const renderText = (text: string) => {
    return text || 'No content';
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center justify-between">
        <label className="font-medium">{label}</label>
        {onEdit && (
          <Button
            variant="primary"
            onClick={isEditing ? handleSave : onToggleEdit}
          >
            {isEditing ? <Save className="h-4 w-4" /> : <Edit2 className="h-4 w-4" />}
          </Button>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="p-4 bg-gray-50 rounded">
          <h4 className="text-sm font-medium mb-2">Current Version</h4>
          <div className="whitespace-pre-wrap">{renderText(oldText)}</div>
        </div>
        <div className="p-4 bg-gray-50 rounded">
          <h4 className="text-sm font-medium mb-2">Proposed Changes</h4>
          {isEditing ? (
            <textarea
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              className="w-full h-full min-h-[100px] p-2 border rounded"
            />
          ) : (
            <div className="whitespace-pre-wrap">{renderText(newText)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const ReviewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [translation, setTranslation] = useState<HearticleTranslation | null>(null);
  const [allTranslations, setAllTranslations] = useState<HearticleTranslation[]>([]);
  const [existingReview, setExistingReview] = useState<Review | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  
  const [formData, setFormData] = useState({
    headline: '',
    mainTextBody: '',
    keywords: [] as string[],
    mainActions: [] as string[],
    comments: ''
  });

  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setError('Hearticle ID is missing');
        setLoading(false);
        return;
      }

      try {
        // Fetch the hearticle
        const hearticleResponse = await api.get(`/api/public/hearticle/${id}`);
        const hearticleData: Hearticle = hearticleResponse.data;

        if (!hearticleData) {
          throw new Error('Hearticle not found');
        }

        setAllTranslations(hearticleData.translations);

        // Find the translation in the current language
        const translationData = hearticleData.translations.find(
          (t) => t.languageCode === currentLanguage
        ) || hearticleData.translations[0];

        if (!translationData) {
          throw new Error('Translation not found');
        }

        setTranslation(translationData);
        setFormData({
          headline: translationData.headline || '',
          mainTextBody: translationData.mainTextBody || '',
          keywords: translationData.keywords || [],
          mainActions: Array.isArray(translationData.mainActions) ? translationData.mainActions : [],
          comments: ''
        });

        // Fetch existing reviews for this translation
        const reviewResponse = await api.get(`/api/review/translation/${translationData.id}`);
        if (reviewResponse.data?.length > 0) {
          setExistingReview(reviewResponse.data[0]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, currentLanguage]);

  const handleSubmitReview = async (status: ReviewStatus) => {
    if (!id || !user?.id || !translation) {
      setError('Missing required data for submission');
      return;
    }

    try {
      // Prepare the changes and review data for the current translation
      const changes: ReviewChange[] = [];
      let translationChanged = false; // Flag to check if translation has been edited

      // Handle simple field changes
      if (formData.headline !== translation.headline) {
        changes.push({
          field: 'HEADLINE',
          previousValue: translation.headline || '',
          newValue: formData.headline
        });
        translationChanged = true;
      }

      if (formData.mainTextBody !== translation.mainTextBody) {
        changes.push({
          field: 'MAIN_TEXT_BODY',
          previousValue: translation.mainTextBody || '',
          newValue: formData.mainTextBody
        });
        translationChanged = true;
      }

      const originalKeywords = translation.keywords || [];
      const newKeywords = formData.keywords;
      
      const maxLength = Math.max(originalKeywords.length, newKeywords.length);
      for (let i = 0; i < maxLength; i++) {
        const oldKeyword = originalKeywords[i] || '';
        const newKeyword = newKeywords[i] || '';
        
        if (oldKeyword !== newKeyword) {
          changes.push({
            field: 'KEYWORD',
            previousValue: oldKeyword,
            newValue: newKeyword,
            listIndex: i
          });
          translationChanged = true;
        }
      }

      // Handle main actions changes
      const originalMainActions = translation.mainActions || [];
      for (let i = 0; i < Math.max(originalMainActions.length, formData.mainActions.length); i++) {
        const oldAction = originalMainActions[i] || '';
        const newAction = formData.mainActions[i] || '';

        if (oldAction !== newAction) {
          changes.push({
            field: 'MAIN_ACTION',
            previousValue: oldAction,
            newValue: newAction,
            listIndex: i
          });
          translationChanged = true;
        }
      }

      // Prepare review data for the current translation
      const reviewData = {
        hearticleTranslation: { id: translation.id },
        reviewer: { id: user.id },
        comments: formData.comments,
        status,
        changes
      };

      // Submit the review for the current translation
      await api.post('/api/review', reviewData);

      // Now, submit reviews for the other translations using the same endpoint
      for (const trans of allTranslations) {
        if (trans.id !== translation.id) {
          // Prepare review data for other translations
          const otherReviewData = {
            hearticleTranslation: { id: trans.id },
            reviewer: { id: user.id },
            comments: formData.comments,
            status,
            changes: [] // Assuming no changes for other translations
          };
          await api.post('/api/review', otherReviewData);
        }
      }

      navigate('/');
    } catch (err) {
      console.error("Error submitting review:", err);
      setError('Failed to submit review');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!translation) return <div>Translation not found</div>;

  return (
    <div className="max-w-7xl mx-auto p-4 text-black">
      <div className="bg-[#CAD4DD] rounded-lg p-6">
        <h2 className="text-2xl font-bold text-[#49ABDB] mb-6">
          {t('review.reviewTranslation')}
        </h2>
        
        <div className="space-y-6">
          <DiffViewer
            oldText={translation.headline}
            newText={formData.headline}
            label={t('review.headline')}
            onEdit={(value) => setFormData({ ...formData, headline: value })}
            isEditing={editingField === 'headline'}
            onToggleEdit={() => setEditingField(editingField === 'headline' ? null : 'headline')}
          />
          
          <DiffViewer
            oldText={translation.mainTextBody}
            newText={formData.mainTextBody}
            label={t('review.mainText')}
            onEdit={(value) => setFormData({ ...formData, mainTextBody: value })}
            isEditing={editingField === 'mainTextBody'}
            onToggleEdit={() => setEditingField(editingField === 'mainTextBody' ? null : 'mainTextBody')}
          />

          <div className="space-y-2">
            <label className="font-medium">{t('review.keywords')}</label>
            <div className="flex flex-wrap gap-2">
              {formData.keywords.map((keyword, index) => (
                <div key={index} className="flex items-center gap-2 bg-white rounded px-2 py-1">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => {
                      const newKeywords = [...formData.keywords];
                      newKeywords[index] = e.target.value;
                      setFormData({ ...formData, keywords: newKeywords });
                    }}
                    className="w-40 px-2 py-1 rounded"
                  />
                  <button
                    onClick={() => {
                      const newKeywords = formData.keywords.filter((_, i) => i !== index);
                      setFormData({ ...formData, keywords: newKeywords });
                    }}
                    className="text-[#49ABDB] hover:opacity-70"
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
              <Button
                variant="outline"
                onClick={() => setFormData({
                  ...formData,
                  keywords: [...formData.keywords, '']
                })}
              >
                {t('review.addKeyword')}
              </Button>
            </div>
          </div>

          <div className="space-y-2">
            <label className="font-medium">{t('review.mainActions')}</label>
            {formData.mainActions.map((action, index) => (
              <div key={index} className="flex gap-2">
                <textarea
                  value={action}
                  onChange={(e) => {
                    const newActions = [...formData.mainActions];
                    newActions[index] = e.target.value;
                    setFormData({ ...formData, mainActions: newActions });
                  }}
                  className="flex-1 p-2 rounded resize-y"
                  rows={3}
                />
                <button
                  onClick={() => {
                    const newActions = formData.mainActions.filter((_, i) => i !== index);
                    setFormData({ ...formData, mainActions: newActions });
                  }}
                  className="text-[#49ABDB] hover:opacity-70"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
            <Button
              variant="outline"
              onClick={() => setFormData({
                ...formData,
                mainActions: [...formData.mainActions, '']
              })}
            >
              {t('review.addAction')}
            </Button>
          </div>

          <div className="space-y-2">
            <label className="font-medium">{t('review.comments')}</label>
            <textarea
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              placeholder={t('review.addComments')}
              rows={4}
              className="w-full p-2 rounded resize-y"
            />
          </div>

          <div className="flex justify-end space-x-4">
            
            <Button
              onClick={() => handleSubmitReview('APPROVED')}
            >
              <Check className="mr-2 h-4 w-4" />
              {t('review.approve')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;