// components/Button.tsx
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'outline';
  children: React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({ 
  variant = 'primary',
  children, 
  className = '',
  ...props 
}) => {
  const baseClasses = 'px-4 py-2 font-bold transition-opacity text-base md:text-lg lg:text-xl';
  
  const variantClasses = {
    primary: 'bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white hover:opacity-90',
    outline: 'border-2 border-[#49ABDB] text-[#49ABDB] hover:bg-[#49ABDB] hover:text-white'
  };

  return (
    <button 
      className={`${baseClasses} ${variantClasses[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};