import React, { useEffect, useState, useRef } from 'react';
import axios from '../axios';
import { Topic, Hearticle } from '../types';
import { getFeaturedTopicForDate, formatDate } from '../utils/featuredTopicUtils';
import { ExternalLink, Upload } from 'lucide-react';

interface DayFeatured {
  date: Date;
  topic: Topic | null;
  hearticle: Hearticle | null;
}

export const FeaturedManagement: React.FC = () => {
  const [upcomingDays, setUpcomingDays] = useState<DayFeatured[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedHearticleId, setSelectedHearticleId] = useState<number | null>(null);

  useEffect(() => {
    loadUpcomingDays();
  }, []);

  const loadUpcomingDays = async () => {
    setLoading(true);
    try {
      const days: DayFeatured[] = await Promise.all(
        Array.from({ length: 14 }, async (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() + i);
          
          const topic = await getFeaturedTopicForDate(date);
          const dateStr = formatDate(date);
          const response = await axios.get<Hearticle[]>('/api/public/hearticles', {
            params: { publicationDate: dateStr }
          });
          
          const hearticle = response.data.find(h => h.topic.id === topic?.id) || null;
          
          return { date, topic, hearticle };
        })
      );
      
      setUpcomingDays(days);
    } catch (error) {
      console.error('Failed to load upcoming days:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !selectedHearticleId) {
      setUploadError('Please select a file and hearticle');
      return;
    }

    // Validate file type
    if (file.type !== 'video/mp4') {
      setUploadError('Only MP4 files are allowed');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);
      
      await axios.post(`/api/admin/users/${selectedHearticleId}/featured`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Clear error and selection after successful upload
      setUploadError(null);
      setSelectedHearticleId(null);
      
      // Optionally reload data
      await loadUpcomingDays();
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadError('Failed to upload featured video');
    }
  };

  const openHearticle = (hearticleId: number) => {
    window.open(`https://staging.heartport.org/hearticle/${hearticleId}`, '_blank');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-bold text-[#111D4E] mb-4">Featured Articles Management</h2>
      
      <div className="grid gap-4">
        {upcomingDays.map(({ date, topic, hearticle }) => (
          <div key={formatDate(date)} className="bg-white p-4 rounded-lg shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">{date.toLocaleDateString()}</p>
                <p className="text-sm text-gray-600">
                  Featured topic: {topic?.name || 'No topic available'}
                </p>
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="text-sm">
                  {hearticle ? (
                    <div className="flex items-center space-x-2">
                      <span className="text-green-600">
                        Article available: {hearticle.translations[0].headline}
                      </span>
                      <button
                        onClick={() => openHearticle(hearticle.id)}
                        className="p-1 hover:bg-gray-100 rounded transition-colors"
                        title="Open hearticle in new tab"
                      >
                        <ExternalLink className="w-5 h-5 text-blue-500" />
                      </button>
                    </div>
                  ) : (
                    <span className="text-red-500">No matching article</span>
                  )}
                </div>
                
                {hearticle && (
                  <button
                    onClick={() => {
                      setSelectedHearticleId(hearticle.id);
                      fileInputRef.current?.click();
                    }}
                    className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                  >
                    <Upload className="w-4 h-4" />
                    <span>Upload Video</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileUpload}
        accept="video/mp4"
      />

      {uploadError && (
        <div className="text-red-500 mt-2">{uploadError}</div>
      )}
    </div>
  );
};