import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { 
  updateConsent, 
  acceptAll, 
  rejectAll
} from '../store/cookieConsentSlice';
import { useTranslation } from 'react-i18next';

interface CookieBannerProps {
  flowerImagePath: string;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ flowerImagePath }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();  // Add this

  const { consent } = useSelector((state: RootState) => state.cookieConsent);

  if (consent.hasInteracted) {
    return null;
  }

  const handleAccept = () => {
    dispatch(acceptAll());
  };

  const handleReject = () => {
    dispatch(rejectAll());
  };

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-lg relative overflow-hidden">
        {/* ... flower background ... */}
        <div className="relative z-20 p-5">
          <h2 className="text-gray-800 text-xl font-medium mb-5">
            {t('cookieConsent.title')}  {/* Translate title */}
          </h2>
          
          <p className="text-gray-600 text-sm mb-6">
            {t('cookieConsent.message')}  {/* Translate message */}
          </p>
          
          <div className="flex justify-end gap-2">
            <button
              onClick={handleReject}
              className="px-5 py-2 text-[#47ABDB] border border-[#47ABDB] hover:bg-[#f0f8ff] transition-colors"
            >
              {t('cookieConsent.reject')}  {/* Translate button */}
            </button>
            <button
              onClick={handleAccept}
              className="px-5 py-2 bg-[#47ABDB] text-white border border-transparent hover:bg-[#0056b3] transition-colors"
            >
              {t('cookieConsent.accept')}  {/* Translate button */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;