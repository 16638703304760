import { Topic } from '../types';
import axios from '../axios';

let cachedTopics: Topic[] | null = null;

export const getActiveTopics = async (): Promise<Topic[]> => {
  if (cachedTopics) return cachedTopics;
  
  const response = await axios.get<Topic[]>('/api/topics/status/ACTIVE');
  cachedTopics = response.data;
  return cachedTopics;
};

export const getFeaturedTopicForDate = async (date: Date): Promise<Topic | null> => {
  const topics = await getActiveTopics();
  if (topics.length === 0) return null;
  
  // Calculate days since epoch for consistent rotation
  const daysSinceEpoch = Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
  console.log("featured topic: ", topics[daysSinceEpoch % topics.length].name);
  return topics[daysSinceEpoch % topics.length];
};

export const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};