import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from '../axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ChevronDown, ChevronUp, Calendar } from 'lucide-react';
import { Hearticle } from '../types';

interface StatisticsData {
  authenticatedInteractionsByType: Record<string, number>;
  anonymousInteractionsByType: Record<string, number>;
  authenticatedShareStats: Record<string, number>;
  anonymousShareStats: Record<string, number>;
  authenticatedUniqueUsers: number;
  anonymousUniqueVisitors: number;
}

const HearticleStatistics = () => {
  const [selectedHearticle, setSelectedHearticle] = useState<string>('');
  const [hearticles, setHearticles] = useState<Hearticle[]>([]);
  const [stats, setStats] = useState<StatisticsData | null>(null);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timeRange, setTimeRange] = useState<'week' | 'month' | 'year'>('week');
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const hasViewStatsPermission = useSelector((state: RootState) => 
    state.auth.user?.roles?.includes('PERMISSION_VIEW_STATISTICS') ?? false
  );

  useEffect(() => {
    const fetchHearticles = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/api/public/hearticles/all');
        if (response.data) {
          setHearticles(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch hearticles:', error);
        setHearticles([]);
        setTotalPages(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHearticles();
  }, [currentPage]);

  const getHearticleHeadline = (hearticle: Hearticle): string => {
    if (!hearticle?.translations?.length) {
      return 'Untitled';
    }
    const defaultTranslation = hearticle.translations.find(t => t.languageCode === 'de') 
      ?? hearticle.translations[0];
    return defaultTranslation.headline;
  };

  useEffect(() => {
    if (selectedHearticle && hasViewStatsPermission) {
      fetchStatistics();
      fetchTimeSeriesData();
    }
  }, [selectedHearticle, timeRange, hasViewStatsPermission]);

  const fetchStatistics = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/interactions/hearticle/${selectedHearticle}/statistics`);
      setStats(response.data);
    } catch (error) {
      console.error('Statistics error:', error);
      setStats(null);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTimeSeriesData = async () => {
    const now = new Date();
    let startDate = new Date();

    switch (timeRange) {
      case 'week':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'month':
        startDate.setMonth(now.getMonth() - 1);
        break;
      case 'year':
        startDate.setFullYear(now.getFullYear() - 1);
        break;
    }

    try {
      const response = await axios.get(`/api/interactions/hearticle/${selectedHearticle}/timeseries`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: now.toISOString()
        }
      });
      setTimeSeriesData(response.data);
    } catch (error) {
      console.error('Failed to fetch time series data:', error);
      setTimeSeriesData([]);
    }
  };

  const calculateTotalViews = (): number => {
    if (!stats) return 0;
    const authenticatedViews = stats.authenticatedInteractionsByType?.READ || 0;
    const anonymousViews = stats.anonymousInteractionsByType?.READ || 0;
    return authenticatedViews + anonymousViews;
  };

  const calculateTotalShares = (): number => {
    if (!stats) return 0;
    const authenticatedShares = Object.values(stats.authenticatedShareStats || {}).reduce((a: number, b: number) => a + b, 0);
    const anonymousShares = Object.values(stats.anonymousShareStats || {}).reduce((a: number, b: number) => a + b, 0);
    return authenticatedShares + anonymousShares;
  };

  if (!hasViewStatsPermission) {
    return null;
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold text-[#111D4E] mb-4">Hearticle Statistics</h2>
      <div className="bg-white rounded-lg p-6 space-y-6">
        <div className="relative text-black">
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="w-full flex justify-between items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-left"
            disabled={isLoading}
          >
            <span>
              {selectedHearticle ? 
                getHearticleHeadline(hearticles.find(h => h.id === Number(selectedHearticle))!) :
                isLoading ? 'Loading...' : 'Select a Hearticle'}
            </span>
            {showDropdown ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
          
          {showDropdown && !isLoading && (
            <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
              {hearticles.map((hearticle) => (
                <button
                  key={hearticle.id}
                  className="w-full px-4 py-2 text-left hover:bg-gray-100"
                  onClick={() => {
                    setSelectedHearticle(String(hearticle.id));
                    setShowDropdown(false);
                  }}
                >
                  {getHearticleHeadline(hearticle)}
                </button>
              ))}
            </div>
          )}
        </div>

        {selectedHearticle && (
          <div className="flex space-x-2">
            {(['week', 'month', 'year'] as const).map((range) => (
              <button
                key={range}
                className={`px-4 py-2 rounded-lg flex items-center ${
                  timeRange === range
                    ? 'bg-[#49ABDB] text-black'
                    : 'bg-gray-100 text-gray-700'
                }`}
                onClick={() => setTimeRange(range)}
              >
                <Calendar size={16} className="mr-2" />
                {range.charAt(0).toUpperCase() + range.slice(1)}
              </button>
            ))}
          </div>
        )}

        {isLoading ? (
          <div className="text-center py-8">Loading...</div>
        ) : selectedHearticle && stats && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm text-gray-500">Total Views</h3>
                <p className="text-2xl font-bold text-[#49ABDB]">
                  {calculateTotalViews()}
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm text-gray-500">Total Shares</h3>
                <p className="text-2xl font-bold text-[#49ABDB]">
                  {calculateTotalShares()}
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-sm text-gray-500">Unique Visitors</h3>
                <p className="text-2xl font-bold text-[#49ABDB]">
                  {(stats.authenticatedUniqueUsers || 0) + (stats.anonymousUniqueVisitors || 0)}
                </p>
              </div>
            </div>

            {timeSeriesData.length > 0 && (
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={timeSeriesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="authenticatedCount" fill="#49ABDB" name="Views" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HearticleStatistics;