import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CookieConsent {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
  preferences: boolean;
  hasInteracted: boolean;
}

interface CookieConsentState {
  consent: CookieConsent;
}

const STORAGE_KEY = 'heartport-public-cookie-consent';

const loadConsentFromStorage = (): CookieConsent => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (stored) {
    return JSON.parse(stored);
  }
  return {
    necessary: false,
    analytics: false,
    marketing: false,
    preferences: false,
    hasInteracted: false,
  };
};

const initialState: CookieConsentState = {
  consent: loadConsentFromStorage(),
};

export const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    updateConsent: (state, action: PayloadAction<Partial<CookieConsent>>) => {
      state.consent = {
        ...state.consent,
        ...action.payload,
        hasInteracted: true,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state.consent));
    },
    acceptAll: (state) => {
      state.consent = {
        necessary: true,
        analytics: true,
        marketing: true,
        preferences: true,
        hasInteracted: true,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state.consent));
    },
    rejectAll: (state) => {
      state.consent = {
        necessary: true, // Necessary cookies are always accepted
        analytics: false,
        marketing: false,
        preferences: false,
        hasInteracted: true,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state.consent));
    },
  },
});

export const { updateConsent, acceptAll, rejectAll } = cookieConsentSlice.actions;
export default cookieConsentSlice.reducer;