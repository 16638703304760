import React, { useState } from 'react';
import { Button } from '../components/Button';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

interface RegenerateHearticleButtonProps {
  hearticleId: number;
}

const RegenerateHearticleButton: React.FC<RegenerateHearticleButtonProps> = ({ hearticleId }) => {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();

  const handleRegenerate = async () => {
    if (isRegenerating) return;
    
    setIsRegenerating(true);
    try {
      await axios.post(`/api/hearticle/${hearticleId}/regenerate`);
    } catch (error) {
      console.error('Failed to regenerate hearticle:', error);
    } finally {
      setIsRegenerating(false);
      setShowConfirmation(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setShowConfirmation(true)}
        disabled={isRegenerating}
        variant="outline"
        className="ml-2"
      >
        {isRegenerating ? t('hearticle.regenerating', 'Regenerating...') : t('hearticle.regenerate', 'Regenerate')}
      </Button>

      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">
              {t('hearticle.regenerateConfirmTitle', 'Confirm Regeneration')}
            </h3>
            <p className="mb-6 text-gray-600">
              {t('hearticle.regenerateConfirmMessage', 'Are you sure you want to regenerate this hearticle? This action cannot be undone.')}
            </p>
            <div className="flex justify-end gap-3">
              <Button
                onClick={() => setShowConfirmation(false)}
                variant="outline"
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                onClick={handleRegenerate}
                variant="primary"
              >
                {t('hearticle.confirmRegenerate', 'Regenerate')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegenerateHearticleButton;