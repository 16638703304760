import { AppDispatch } from '../store';
import { initLanguage } from '../store/languageSlice';
import i18n from 'i18next';

type LanguageConfig = {
  defaultLanguage: string;
  supportedLanguages: string[];
};

export class LanguageDetector {
  private config: LanguageConfig;

  constructor(config: LanguageConfig) {
    this.config = config;
  }

  detectLanguage(): string {
    return (
      this.getLanguageFromURL() ||
      this.getLanguageFromLocalStorage() ||
      this.getLanguageFromBrowser() ||
      this.config.defaultLanguage
    );
  }

  private getLanguageFromURL(): string | null {
    if (typeof window === 'undefined') return null;
    
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get('lang');
    
    if (langParam && this.isSupported(langParam)) {
      return langParam;
    }
    
    const pathSegments = window.location.pathname.split('/');
    const pathLang = pathSegments[1];
    
    if (pathLang && this.isSupported(pathLang)) {
      return pathLang;
    }
    
    return null;
  }

  private getLanguageFromLocalStorage(): string | null {
    if (typeof window === 'undefined') return null;
    
    const savedLang = localStorage.getItem('preferredLanguage');
    return savedLang && this.isSupported(savedLang) ? savedLang : null;
  }

  private getLanguageFromBrowser(): string | null {
    if (typeof window === 'undefined') return null;
    
    const browserLangs = navigator.languages || [navigator.language];
    
    for (const lang of browserLangs) {
      const simpleLang = lang.split('-')[0].toLowerCase();
      if (this.isSupported(simpleLang)) {
        return simpleLang;
      }
    }
    
    return null;
  }

  private isSupported(lang: string): boolean {
    return this.config.supportedLanguages.includes(lang.toLowerCase());
  }

  saveLanguagePreference(lang: string): void {
    if (typeof window !== 'undefined' && this.isSupported(lang)) {
      localStorage.setItem('preferredLanguage', lang);
    }
  }
}

// Initialize language detection
export const initializeLanguage = (dispatch: AppDispatch) => {
  const languageDetector = new LanguageDetector({
    defaultLanguage: 'de',
    supportedLanguages: ['de', 'en']
  });

  const detectedLanguage = languageDetector.detectLanguage();
  
  // Use the initLanguage action instead
  dispatch(initLanguage(detectedLanguage));
  
  // Update i18next
  i18n.changeLanguage(detectedLanguage);
  
  // Save preference
  languageDetector.saveLanguagePreference(detectedLanguage);
};
