import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { SubscriptionLevel, SubscriptionStatus } from '../store/authSlice';
import clouds from '../assets/images/clouds.png';
import HearticleStatistics from './HearticleStatistics';
import UserManagement from './UserManagement';
import PlaylistManagement from './PlaylistManagement';
import { Camera, Save, X } from 'lucide-react';
import axios from '../axios';
import { FeaturedManagement } from './FeaturedManagement';

const CustomAlert = ({ message, type = 'success', onClose }: { message: string; type?: 'success' | 'error'; onClose?: () => void }) => (
  <div className={`rounded-lg p-4 ${type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} relative`}>
    <span className="block pr-8">{message}</span>
    {onClose && (
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-lg font-semibold"
      >
        ×
      </button>
    )}
  </div>
);


const Profile = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [activeTab, setActiveTab] = useState('profile');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [editingUsername, setEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState(user?.username || '');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Calculate the daily changing hue value
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };

  const handleUsernameSubmit = async () => {
    try {
      await axios.put(`/api/admin/users/${user?.id}/username`, { username: newUsername });
      setEditingUsername(false);
      setSuccessMessage('Username updated successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error: any) {
      setUsernameError(error.response?.data?.message || 'Failed to update username');
    }
  };

  const handlePasswordReset = async () => {
    try {
      await axios.post('/api/auth/forgot-password', null, {
        params: { email: user?.email }
      });
      setSuccessMessage('Password reset instructions have been sent to your email.');
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (error: any) {
      setUsernameError(error.response?.data?.message || 'Failed to process password reset request.');
    }
  };

  const formatPermissionName = (permission: string): string => {
    return permission
      .replace('PERMISSION_', '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const formatRoleName = (roleName: string): string => {
    return roleName.replace('ROLE_', '').toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatSubscriptionLevel = (level: SubscriptionLevel): string => {
    return level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();
  };

  const getSubscriptionStatusColor = (status: SubscriptionStatus): string => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-green-500';
      case 'INACTIVE':
        return 'bg-gray-500';
      case 'PENDING':
        return 'bg-yellow-500';
      case 'CANCELLED':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    // Validate file type
    if (!file.type.startsWith('image/')) {
      setUploadError('Please select an image file');
      return;
    }
  
    // Validate file size (e.g., 5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('Image size should be less than 5MB');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      setUploadError(null);
      
      // Use the configured Axios instance
      const response = await axios.post(
        `/api/admin/users/${user?.id}/profile-image`,
        formData
        // No need to set headers here
      );
  
      // Force a reload of the profile image by adding a timestamp
      const timestamp = new Date().getTime();
      const imageElement = document.getElementById('profile-image') as HTMLImageElement;
      if (imageElement) {
        imageElement.src = `/api/admin/users/${user?.id}/profile-image?t=${timestamp}`;
      }
    } catch (error) {
      console.error('Full error object:', error);
      setUploadError('Failed to upload image. Please try again.');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const renderProfileTab = () => (
    <div className="space-y-6">
      {successMessage && (
        <CustomAlert 
          message={successMessage}
          type="success"
          onClose={() => setSuccessMessage(null)}
        />
      )}
      <div>
        <h2 className="text-xl font-bold text-[#111D4E] mb-4">Account Details</h2>
        <div className="flex items-start space-x-6">
          <div className="relative group">
            <div className="w-32 h-32 rounded-full overflow-hidden bg-gray-200 relative">
              <img
                id="profile-image"
                src={`/api/admin/users/${user?.id}/profile-image`}
                alt={user?.username}
                className="w-full h-full object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user?.username || '')}&background=random`;
                }}
              />
              <button
                onClick={triggerFileInput}
                className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center"
              >
                <Camera className="w-8 h-8 text-white" />
              </button>
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            {uploadError && (
              <p className="text-red-500 text-sm mt-2">{uploadError}</p>
            )}
          </div>
          <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
              <label className="block text-sm font-medium text-[#111D4E] mb-1">
                Username
              </label>
              {editingUsername ? (
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    className="flex-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-[#49ABDB] text-black"
                  />
                  <button
                    onClick={handleUsernameSubmit}
                    className="p-2 text-green-600 hover:text-green-700"
                  >
                    <Save size={20} />
                  </button>
                  <button
                    onClick={() => {
                      setEditingUsername(false);
                      setNewUsername(user?.username || '');
                    }}
                    className="p-2 text-red-600 hover:text-red-700"
                  >
                    <X size={20} />
                  </button>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <p className="text-lg font-medium">{user?.username}</p>
                  <button
                    onClick={() => setEditingUsername(true)}
                    className="text-sm text-[#49ABDB] hover:text-[#3789B9]"
                  >
                    Edit
                  </button>
                </div>
              )}
              {usernameError && (
                <p className="text-red-500 text-sm mt-1">{usernameError}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-[#111D4E] mb-1">
                Email
              </label>
              <p className="text-lg font-medium">{user?.email}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Keep the rest of the profile tab content */}
      <div>
        <h2 className="text-xl font-bold text-[#111D4E] mb-4">Permissions</h2>
        <div className="flex flex-wrap gap-2">
          {user?.roles?.map((permission, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-[#49ABDB] text-white rounded-full text-sm"
            >
              {formatPermissionName(permission)}
            </span>
          ))}
        </div>
      </div>

      {/*<div className="space-y-4">
        <h2 className="text-xl font-bold text-[#111D4E]">Security</h2>
        <button className="px-4 py-2 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white font-bold rounded hover:opacity-90 transition-opacity">
          Change Password
        </button>
      </div> */}
    </div>
  );

  return (
    <div style={backgroundStyle} className="min-h-screen">
      <div style={cloudImageStyle}></div>
      <div style={overlayStyle}></div>

      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-[#49ABDB] mb-8">Profile Settings</h1>

          <div className="bg-[#CAD4DD] rounded-lg shadow-lg p-6">
            <div className="flex border-b border-gray-300 mb-6">
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'profile'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => setActiveTab('profile')}
              >
                Profile Information
              </button>
              <button
                className={`px-4 py-2 font-bold ${
                  activeTab === 'subscription'
                    ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                    : 'text-[#111D4E]'
                }`}
                onClick={() => setActiveTab('subscription')}
              >
                Subscription
              </button>
              {user?.roles?.includes('PERMISSION_VIEW_STATISTICS') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'statistics'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('statistics')}
                >
                  Statistics
                </button>
              )}
              {user?.roles?.includes('PERMISSION_ADMIN') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'users'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('users')}
                >
                  Users
                </button>
              )}
              {user?.roles?.includes('PERMISSION_PLAYLIST') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'playlists'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('playlists')}
                >
                  Playlists
                </button>
              )}
              {user?.roles?.includes('PERMISSION_ADMIN') && (
                <button
                  className={`px-4 py-2 font-bold ${
                    activeTab === 'featured'
                      ? 'text-[#49ABDB] border-b-2 border-[#49ABDB]'
                      : 'text-[#111D4E]'
                  }`}
                  onClick={() => setActiveTab('featured')}
                >
                  Featured
                </button>
              )}
            </div>

            {activeTab === 'profile' && renderProfileTab()}

            {activeTab === 'subscription' && (
              <div>
                <h2 className="text-xl font-bold text-[#111D4E] mb-4">Your Subscription</h2>
                <div className="bg-white rounded-lg p-6 space-y-4">
                  <div>
                    <p className="text-sm text-[#111D4E] mb-2">Current Plan</p>
                    <div className="inline-block px-4 py-2 bg-[#49ABDB] text-white rounded-lg text-base font-medium">
                      {user?.subscriptionLevel ? formatSubscriptionLevel(user.subscriptionLevel) : 'Free'}
                    </div>
                  </div>

                  <div>
                    <p className="text-sm text-[#111D4E] mb-2">Status</p>
                    <div className={`inline-block px-3 py-1 text-white rounded-full text-sm ${
                      user?.subscriptionStatus ? 
                      getSubscriptionStatusColor(user.subscriptionStatus) : 
                      'bg-gray-500'
                    }`}>
                      {user?.subscriptionStatus || 'INACTIVE'}
                    </div>
                  </div>

                  <div className="pt-4 border-t border-gray-200">
                    <button
                      className="px-4 py-2 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white font-bold rounded hover:opacity-90 transition-opacity"
                      onClick={() => {
                        // Add upgrade subscription handler
                        console.log('Upgrade subscription clicked');
                      }}
                    >
                      Upgrade Plan
                    </button>
                  </div>

                  <p className="text-sm text-gray-600 mt-4">
                    Manage your subscription and billing information. Premium features coming soon.
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'statistics' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <HearticleStatistics />
            )}
            {activeTab === 'users' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <UserManagement />
            )}
            {activeTab === 'playlists' && user?.roles?.includes('PERMISSION_PLAYLIST') && (
              <PlaylistManagement />
            )}
            {activeTab === 'featured' && user?.roles?.includes('PERMISSION_ADMIN') && (
              <FeaturedManagement />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;