import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../axios'; // Import the axios instance we just created

export function useLocalizedContent(contentKey: string) {
  const { i18n } = useTranslation();
  const [content, setContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/content/${contentKey}`, {
          params: { lang: i18n.language },
        });
        setContent(response.data.content);
        setError(null);
      } catch (err) {
        setError('Failed to fetch content');
        setContent(null);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [contentKey, i18n.language]);

  return { content, loading, error };
}