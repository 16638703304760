import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { Hearticle, HearticleTranslation } from '../types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import PlayButton from './PlayButton';
import ArticleCard from './ArticleCard';

interface RecommendationsComponentProps {
  personalizedCount: number;
  generalCount: number;
  headline: string;
  subheadline: string;
  showReadCount?: boolean;
}

const RecommendationsComponent: React.FC<RecommendationsComponentProps> = ({
  personalizedCount,
  generalCount,
  headline,
  subheadline,
  showReadCount = false,
}) => {
  const [recommendations, setRecommendations] = useState<Hearticle[]>([]);
  const [readCount, setReadCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  const getTranslation = (hearticle: Hearticle): HearticleTranslation => {
    const translation = hearticle.translations.find(t => t.languageCode === currentLanguage);
    return translation || hearticle.translations[0];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recommendationsResponse, readCountResponse] = await Promise.all([
          axios.get('/api/hearticles/recommendations', {
            params: { personalizedCount, generalCount },
          }),
          showReadCount && isAuthenticated ? axios.get('/api/interactions/user/read-count') : Promise.resolve({ data: 0 }),
        ]);
        
        setRecommendations(recommendationsResponse.data);
        if (showReadCount && isAuthenticated) {
          setReadCount(readCountResponse.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(t('errors.failedToFetchRecommendations'));
        setLoading(false);
      }
    };

    fetchData();
  }, [personalizedCount, generalCount, t, showReadCount, isAuthenticated]);

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = `${process.env.REACT_APP_API_URL}/uploads/topic_images/nutrition_medicine.png`;
    target.onerror = null;
  };

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png') imageName = 'recover_immunity_&_supplements.png';
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  if (loading) {
    return <p className="text-center">{t('loading')}</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="mx-0 lg:mx-4 my-32 bg-white">
      <p className="text-2xl font-bold text-[#111D4E] p-4">{subheadline}</p>
      <h2 className="text-4xl font-bold mb-6 text-[#47ABDB] p-4">{headline}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
      {recommendations.map((article) => (
          <ArticleCard
            key={article.id}
            article={article}
            translation={getTranslation(article)}
            imageSize="md"
          />
        ))}
      </div>
      
      {showReadCount && isAuthenticated && (
        <div className="text-center mt-12 pb-20">
          <p className="text-[#111D4E] text-xl font-bold mb-4">
            {t('readProgress.subtitle')}
          </p>
          <h3 className="text-[#47ABDB] text-3xl font-bold mb-4">
            {t('readProgress.title')}
          </h3>
          <p className="text-[#47ABDB] text-4xl font-bold">
            {readCount}
          </p>
          <p className="text-[#47ABDB] text-3xl">
            Hearticles
          </p>
        </div>
      )}
    </div>
  );
};

export default RecommendationsComponent;