import React from 'react';
import { useTranslation } from 'react-i18next';
import science_cares from '../assets/images/hearts_science_cares.png';
import videoSource from '../assets/videos/hearts_science_cares.mp4';

const VideoSection = () => {
  const { t } = useTranslation();

  return (
    <div className="mx-0 md:mx-4 mt-32 mb-32 relative">
      <div className="mx-auto">
        <div className="relative bg-gray-900 overflow-hidden w-full">
          {/* Video Container with max-height */}
          <div className="relative w-full">
            <video
              className="h-[90vh] md:h-auto md:w-full max-h-[90vh] object-cover"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={videoSource} type="video/mp4" />
              {t('video.notSupported')}
            </video>

            {/* Text Overlay with smaller text up to 1500px */}
            <div className="absolute inset-0 bg-black/50 flex">
              <div className="w-full lg:w-2/3 xl:w-1/2 pt-8 px-8 flex flex-col text-white">
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl 2xl:text-5xl font-bold mb-2 2xl:mb-12 text-[#49ABDB]">
                  {t('video.title')}
                </h2>
                
                <p className="text-xs sm:text-sm md:text-base lg:text-base 2xl:text-xl mb-2 2xl:mb-8">
                  {t('video.paragraph1')}
                </p>
                
                <p className="text-xs sm:text-sm md:text-base lg:text-base 2xl:text-xl italic mb-2 2xl:mb-8 text-[#49ABDB]">
                  {t('video.paragraph2')}
                </p>
                
                <p className="text-xs sm:text-sm md:text-base lg:text-base 2xl:text-xl italic font-bold text-[#49ABDB]">
                  {t('video.paragraph3')}
                </p>
              </div>
            </div>

            {/* Bottom Image - Adjusted sizes */}
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
              <img
                src={science_cares}
                alt={t('video.imageAlt')}
                className="w-24 h-24 sm:w-28 sm:h-28 md:w-32 md:h-32 lg:w-48 lg:h-48 2xl:w-80 2xl:h-80 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;