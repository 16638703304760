import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo512.png';
import lungflower from '../assets/images/lungflower.png';
import flower from '../assets/images/flower.png';

import lzillner from '../assets/images/lzillner.png';
import cwallner from '../assets/images/cwallner.png';
import hdunzendorfer from '../assets/images/hdunzendorfer.png';
import jleber from '../assets/images/jleber.png';
import mandreas from '../assets/images/mandreas.png';
import hwidhalm from '../assets/images/hwidhalm.png';
import fnentwich from '../assets/images/fnentwich.png';
import cgabrielli from '../assets/images/cgabrielli.png';
import crainer from '../assets/images/crainer.png';
import tramsauer from '../assets/images/tramsauer.png';
import klampferer from '../assets/images/klampferer.jpeg';

interface ProfileProps {
  name: string;
  title: string;
  boldLine1?: string;
  boldLine2?: string;
  normalLine1?: string;
  normalLine2?: string;
  image: string;
  className?: string;
  bio?: React.ReactNode;
}

interface TeamMember extends ProfileProps {
  isPlaceholder?: false;
}

interface PlaceholderMember {
  isPlaceholder: true;
}

type ConferenceTeamMember = TeamMember | PlaceholderMember;

const Profile: React.FC<ProfileProps> = ({ 
  name, 
  title,
  normalLine1 = '',
  normalLine2 = '',
  image,
  className = '',
  bio
}) => {
  const [showBio, setShowBio] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`flex flex-col items-center relative ${className}`}>
      <div 
        className="relative"
        onMouseEnter={() => setShowBio(true)}
        onMouseLeave={() => setShowBio(false)}
        onClick={() => setShowBio(!showBio)}
      >
        <div className="w-40 h-40 rounded-full bg-[#d6d6d4] mb-8">
          <img 
            src={image} 
            alt={name} 
            className="w-40 h-40 rounded-full object-cover"
          />
        </div>
        
        {bio && showBio && (
          <div className="fixed inset-x-4 lg:inset-x-8 top-1/4 z-50">
            <div className="bg-[#90C4D9] text-black p-6 shadow-lg max-w-screen-xl mx-auto">
              <div className="max-h-[60vh] overflow-y-auto text-base lg:text-lg leading-relaxed">
                {bio}
              </div>
            </div>
          </div>
        )}
      </div>
      
      <h3 className="w-56 font-bold text-sm">{name}</h3>
      <h3 className="w-56 font-bold text-sm">{title}</h3>
      {normalLine1 && <p className="w-56 text-sm">{normalLine1}</p>}
      {normalLine2 && <p className="w-56 text-sm">{normalLine2}</p>}
    </div>
  );
};


const Crew: React.FC = () => {
  const { t } = useTranslation();

  const conferenceTeam: ConferenceTeamMember[] = [
    { 
      name: "Mag. Liliane Zillner", 
      title: t("crew.conferenceTeam.lzillner.title"), 
      normalLine1: t("crew.conferenceTeam.lzillner.normalLine1"),
      normalLine2: t("crew.conferenceTeam.lzillner.normalLine2"),
      image: lzillner,
      isPlaceholder: false,
      bio: (
        <>
          <p>{t("crew.conferenceTeam.lzillner.bio.paragraph1")}</p>
          <p className="mt-4">{t("crew.conferenceTeam.lzillner.bio.paragraph2")}</p>
        </>
      )    
    },
    { 
      name: "Clara Wallner Bsc", 
      title: t("crew.conferenceTeam.cwallner.title"), 
      normalLine1: t("crew.conferenceTeam.cwallner.normalLine1"),
      normalLine2: '',
      image: cwallner,
      isPlaceholder: false,
    },
    { 
      name: "Mag. Thomas Ramsauer", 
      title: t("crew.conferenceTeam.tramsauer.title"), 
      normalLine1: t("crew.conferenceTeam.tramsauer.normalLine1"),
      normalLine2: '',
      image: tramsauer,
      isPlaceholder: false
    },
    { isPlaceholder: true },
    { 
      name: "Florian Nentwich", 
      title: t("crew.conferenceTeam.fnentwich.title"), 
      normalLine1: t("crew.conferenceTeam.fnentwich.normalLine1"),
      normalLine2: t("crew.conferenceTeam.fnentwich.normalLine2"),
      image: fnentwich,
      isPlaceholder: false
    },
    { 
      name: "Melanie Klampferer cand. med.", 
      title: t("crew.conferenceTeam.mklapmpferer.title"), 
      normalLine1: t("crew.conferenceTeam.mklapmpferer.normalLine1"),
      normalLine2: t("crew.conferenceTeam.mklapmpferer.normalLine2"),
      image: klampferer,
      isPlaceholder: false
    }
  ];

  const aiArtTeam = [
    { 
      name: "Herwig Dunzendorfer", 
      title: t("crew.aiArtTeam.hdunzendorfer.title"), 
      normalLine1: t("crew.aiArtTeam.hdunzendorfer.normalLine1"),
      normalLine2: t("crew.aiArtTeam.hdunzendorfer.normalLine2"),
      image: hdunzendorfer,
      bio:(
        <>
          <p>{t("crew.aiArtTeam.hdunzendorfer.bio.paragraph1")}</p>
          <p>{t("crew.aiArtTeam.hdunzendorfer.bio.paragraph2")}</p>
          <p>{t("crew.aiArtTeam.hdunzendorfer.bio.paragraph3")}</p>
          <p>{t("crew.aiArtTeam.hdunzendorfer.bio.paragraph4")}</p>
        </>
    )},
    { 
      name: "Chiara Gabrielli Bsc", 
      title: t("crew.aiArtTeam.cgabrielli.title"), 
      normalLine1: t("crew.aiArtTeam.cgabrielli.normalLine1"),
      normalLine2: '',
      image: cgabrielli,
      bio:(
      <>
        <p>{t("crew.aiArtTeam.cgabrielli.bio.paragraph1")}</p>
        <p>{t("crew.aiArtTeam.cgabrielli.bio.paragraph2")}</p>
      </>
    )},
    { 
      name: "Jonathan Leber", 
      title: t("crew.aiArtTeam.jleber.title"), 
      normalLine1: t("crew.aiArtTeam.jleber.normalLine1"),
      normalLine2: t("crew.aiArtTeam.jleber.normalLine2"),
      image: jleber 
    }
  ];

  const scientificTeam = [
    { 
      name: "Prof. Martin Andreas", 
      title: t("crew.scientificTeam.mandreas.title"), 
      normalLine1: t("crew.scientificTeam.mandreas.normalLine1"),
      normalLine2: t("crew.scientificTeam.mandreas.normalLine2"),
      image: mandreas 
    },
    { 
      name: "Prof. Harald Widhalm", 
      title: t("crew.scientificTeam.hwidhalm.title"), 
      normalLine1: t("crew.scientificTeam.hwidhalm.normalLine1"),
      normalLine2: '',
      image: hwidhalm 
    },
    { 
      name: "Dr. Christian Rainer", 
      title: '', 
      normalLine1: t("crew.scientificTeam.crainer.title"),
      normalLine2: '',
      image: crainer 
    }
  ];


  return (
    <div className="min-h-screen bg-[#CDE1EE] text-black">
      {/* Main Headline */}
      <div className="py-12 px-4 lg:px-16">
        <h1 className="text-4xl lg:text-5xl font-bold mb-12 text-center text-[#48ABDB]">
          {t("crew.mainHeadline")}
        </h1>
      </div>

      {/* First Row: Text Box + Conference Team | Lung Flower */}
      <div className="relative mb-12 lg:mb-72 w-full">
        <div className="flex flex-col lg:flex-row">
          {/* Left side content */}
          <div className="lg:w-2/3 flex flex-col px-4 lg:px-16">
            {/* Text Box */}
            <div className="bg-[#90C4D9] p-6 mb-32 text-black">
              <p className="text-lg leading-relaxed">
                {t("crew.textBox.paragraph1.normal")}
                <strong className="text-white">{t("crew.textBox.paragraph1.bold")}</strong>
                {t("crew.textBox.paragraph1.normal2")}
                <strong className="text-white">{t("crew.textBox.paragraph1.bold2")}</strong>
                {t("crew.textBox.paragraph1.normal3")}
              </p>
              <p className="text-lg leading-relaxed mt-4">
                {t("crew.textBox.paragraph2.normal")}
                <strong className="text-white">{t("crew.textBox.paragraph2.bold")}</strong>
                {t("crew.textBox.paragraph2.normal2")}
                <strong className="text-white">{t("crew.textBox.paragraph2.bold2")}</strong>
                {t("crew.textBox.paragraph2.normal3")}
                <strong className="text-white">{t("crew.textBox.paragraph2.bold3")}</strong>
                {t("crew.textBox.paragraph2.normal4")}
              </p>
              <p className="text-lg leading-relaxed mt-4">
                {t("crew.textBox.paragraph3.normal")}
                <strong className="text-white">{t("crew.textBox.paragraph3.bold")}</strong>
                {t("crew.textBox.paragraph3.normal2")}
                <strong className="text-white">{t("crew.textBox.paragraph3.bold2")}</strong>
                {t("crew.textBox.paragraph3.normal3")}
                <strong className="text-white">{t("crew.textBox.paragraph3.bold3")}</strong>
                {t("crew.textBox.paragraph3.normal4")}
              </p>
            </div>

            {/* Conference Team Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {conferenceTeam.map((member, index) => {
                if (member.isPlaceholder) return <div key={index} className="hidden lg:block lg:mt-16" />;
                return (
                  <Profile 
                    key={index}
                    {...member}
                    className={`block ${member.name === "Florian Nentwich" ? "md:col-span-1 lg:col-span-1 lg:mt-16" : ""}
                    ${member.name === "Melanie Klampferer cand. med." ? "md:col-span-1 lg:col-span-1 lg:mt-16" : ""}`}
                  />
                );
              })}
            </div>
          </div>

          {/* Lung Flower - positioned at the very right */}
          <div className="hidden lg:block absolute right-0 top-0 w-1/4">
            <img 
              src={lungflower} 
              alt={t("common.logoAlt")} 
              className="w-full object-cover"
              style={{ transform: 'translateX(0%)' }}
            />
          </div>
        </div>

        {/* Mobile Lung Flower */}
        <div className="lg:hidden w-full mt-8">
          <img src={lungflower} alt={t("common.logoAlt")} className="w-full h-auto -mr-72" />
        </div>
      </div>

      {/* Second Row: Flower | AI and ART Team */}
      <div className="relative mb-12 lg:mb-72 w-full">
        <div className="flex flex-col lg:flex-row">
          {/* Decorative Flower - positioned at the very left */}
          <div className="hidden lg:block absolute left-0 top-0 w-1/2">
            <img 
              src={flower} 
              alt={t("common.logoAlt")} 
              className="h-full w-full -translate-x-1/3"
            />
          </div>
          
          {/* AI and ART Team Section */}
          <div className="lg:w-2/3 lg:ml-auto px-4 lg:px-16">
            <h2 className="text-3xl lg:text-8xl font-bold mb-20 text-[#48ABDB]">
              {t("crew.aiArtTeamHeadline")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {aiArtTeam.map((member, index) => (
                <Profile key={index} {...member} />
              ))}
            </div>
          </div>
        </div>

        {/* Mobile Flower */}
        <div className="lg:hidden w-full mt-8">
            <img 
                src={flower} 
                alt={t("common.logoAlt")} 
                className="max-w-full h-auto -ml-32 md:-ml-56" 
            />
        </div>
      </div>

      {/* Third Row: Scientific Committee */}
      <div className="px-4 lg:px-16 lg:pt-72 pb-48">
        <div className="lg:w-full">
          <h2 className="lg:w-3/4 text-3xl lg:text-8xl font-bold mb-20 text-[#48ABDB]">
            {t("crew.scientificCommitteeHeadline")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {scientificTeam.map((member, index) => (
              <Profile key={index} {...member} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crew;