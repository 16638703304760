import React, { useState, useEffect } from 'react';

interface Reviewer {
  id: number;
  username: string;
  email: string;
}

interface Review {
  id: number;
  reviewer: Reviewer;
  status: string;
}

interface ReviewBadgeProps {
  review: Review | null;
  t: (key: string) => string;
}

const ReviewBadge: React.FC<ReviewBadgeProps> = ({ review, t }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const reviewerId = review?.reviewer?.id;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkImageExists = async (url: string): Promise<boolean> => {
      try {
        const response = await fetch(url);
        return response.status === 200;
      } catch {
        return false;
      }
    };

    const loadImage = async (): Promise<void> => {
      if (!reviewerId) {
        setImageUrl(null);
        return;
      }

      const extensions = ['jpg', 'jpeg', 'png'];
      setImageUrl(null);
      
      for (const ext of extensions) {
        const url = `${process.env.REACT_APP_API_URL}/uploads/users/${reviewerId}.${ext}`;
        if (await checkImageExists(url)) {
          setImageUrl(url);
          break;
        }
      }
    };

    loadImage();
  }, [reviewerId, apiUrl]);

  if (!review) return null;

  return (
    <div className="flex flex-col items-center">
      {imageUrl && (
        <div className="w-24 h-24 rounded-full bg-[#d6d6d4] mb-1">
          <img 
            src={imageUrl} 
            alt={review.reviewer?.username || 'Reviewer'}
            className="w-full h-full rounded-full object-cover"
          />
        </div>
      )}
      <div className="mt-auto text-[#111D4E] px-2 py-1 text-xs text-center">
        {t('review.thisHearticleIsReviewed')}
      </div>
    </div>
  );
};

export default ReviewBadge;