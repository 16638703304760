import React, { useState, useRef } from 'react';
import play from '../assets/images/play.png';
import pause from '../assets/images/pause.png';

interface PlayButtonProps {
  hearticleId: number;
  translationId: number;
}

const PlayButton: React.FC<PlayButtonProps> = ({ hearticleId, translationId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioUrl = `${process.env.REACT_APP_API_URL}/uploads/${hearticleId}/${translationId}.mp3`;
  
  const handleClick = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div>
      <button 
        onClick={handleClick}
        className="flex items-center justify-center w-12 h-12 rounded-full hover:opacity-90 transition-opacity duration-200"
      >
        <img 
          src={isPlaying ? pause : play} 
          alt={isPlaying ? "Pause" : "Play"} 
          className="w-full h-full" 
        />
      </button>
      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={handleAudioEnded}
        className="hidden"
      />
    </div>
  );
};

export default PlayButton;