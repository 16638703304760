import React, { useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import { login } from '../store/authSlice';
import clouds from '../assets/images/clouds.png';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.auth.loading === 'pending');
  const { t } = useTranslation();

  // Calculate the daily changing hue value
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const backgroundStyle = {
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    overflow: 'hidden' as 'hidden',
  };

  const cloudImageStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${clouds})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
    opacity: 1.0,
    zIndex: 0,
  };

  const overlayStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0))',
    zIndex: 1,
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      const result = await dispatch(login({ username, password })).unwrap();
      console.log('Login result:', result);
      // Check if token is stored
      const storedToken = localStorage.getItem('token');
      console.log('Token stored in localStorage:', storedToken ? 'Present' : 'Missing');
      navigate('/');
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err || 'Login failed. Please check your credentials.');
    }
  };

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    try {
      await axios.post('/api/auth/forgot-password', null, {
        params: { email }
      });
      setSuccessMessage('Password reset instructions have been sent to your email.');
      setShowForgotPassword(false);
      setEmail('');
    } catch (err: any) {
      console.error('Forgot password error:', err);
      setError(err.response?.data?.message || 'Failed to process password reset request.');
    }
  };

  return (
    <div style={backgroundStyle} className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div style={cloudImageStyle}></div>
      <div style={overlayStyle}></div>

      <div className="max-w-md w-full space-y-8 relative z-10">
        <div>
          <h2 className="mt-6 text-center text-4xl font-bold text-[#49ABDB]">
            {showForgotPassword ? t('auth.resetPassword.title') : t('auth.login.welcomeBack')}
          </h2>
          <p className="mt-2 text-center text-base text-[#111D4E]">
            {showForgotPassword 
              ? t('auth.resetPassword.subtitle')
              : t('auth.login.subtitle')}
          </p>
        </div>

        <div className="bg-[#CAD4DD] p-8 rounded-lg shadow-lg">
          {!showForgotPassword ? (
            <form className="mt-8 space-y-6" onSubmit={handleLogin}>
              <div className="space-y-4">
                <div>
                  <label htmlFor="username" className="block text-sm font-medium text-[#111D4E]">
                    {t('auth.fields.username')}
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                    placeholder={t('auth.placeholders.username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-[#111D4E]">
                    {t('auth.fields.password')}
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                    placeholder={t('auth.placeholders.password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              {error && (
                <div className="text-red-500 text-sm text-center">{error}</div>
              )}
              {successMessage && (
                <div className="text-green-600 text-sm text-center">{successMessage}</div>
              )}

              <button
                type="submit"
                disabled={loading}
                className={`group relative w-full flex justify-center px-4 py-2 text-base font-bold rounded text-white bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] ${
                  loading ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90 transition-opacity'
                }`}
              >
                {loading ? t('auth.buttons.signingIn') : t('auth.buttons.signIn')}
              </button>
            </form>
          ) : (
            <form className="mt-8 space-y-6" onSubmit={handleForgotPassword}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-[#111D4E]">
                  {t('auth.fields.email')}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-1 appearance-none relative block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#49ABDB] focus:border-[#49ABDB] sm:text-sm"
                  placeholder={t('auth.placeholders.email')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {error && (
                <div className="text-red-500 text-sm text-center">{error}</div>
              )}

              <button
                type="submit"
                className="group relative w-full flex justify-center px-4 py-2 text-base font-bold rounded text-white bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
              >
                {t('auth.buttons.sendResetInstructions')}
              </button>
            </form>
          )}
        </div>

        <div className="mt-4 text-center space-y-2">
          {!showForgotPassword ? (
            <>
              <p className="text-sm text-[#111D4E]">
                {t('auth.login.noAccount')}{' '}
                <button
                  onClick={() => navigate('/signup')}
                  className="font-medium text-[#49ABDB] hover:opacity-80 transition-opacity"
                >
                  {t('auth.buttons.signUp')}
                </button>
              </p>
              <p className="text-sm text-[#111D4E]">
                <button
                  onClick={() => setShowForgotPassword(true)}
                  className="font-medium text-[#49ABDB] hover:opacity-80 transition-opacity"
                >
                  {t('auth.buttons.forgotPassword')}
                </button>
              </p>
            </>
          ) : (
            <p className="text-sm text-[#111D4E]">
              <button
                onClick={() => setShowForgotPassword(false)}
                className="font-medium text-[#49ABDB] hover:opacity-80 transition-opacity"
              >
                {t('auth.buttons.backToSignIn')}
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;