import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
  currentLanguage: string;
  isInitialized: boolean;
}

const initialState: LanguageState = {
  currentLanguage: 'de',
  isInitialized: false,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload;
      state.isInitialized = true;
    },
    initLanguage: (state, action: PayloadAction<string>) => {
      if (!state.isInitialized) {
        state.currentLanguage = action.payload;
        state.isInitialized = true;
      }
    },
  },
});

export const { setLanguage, initLanguage } = languageSlice.actions;

export default languageSlice.reducer;